<template>

  <b-navbar  class="nav w-100 p-4">
    <b-navbar-brand>SURVIVE</b-navbar-brand>
  </b-navbar>

</template>

<script>

export default {
  name: "navBar",

}
</script>

<style scoped>
.nav{
  background-color: #222831;
}
.navbar-light .navbar-brand ,.navbar-light .navbar-nav .nav-link{
  background-color: inherit;
  font-family: "Monoton";
  color: #fff ;
  font-size: 30px;
  margin-left:2%;
  text-align: center;
}


@font-face {
  font-family: "Monoton";
  src: url("../assets/Monoton-Regular.ttf");
}


</style>